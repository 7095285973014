import React, { useEffect, useState } from 'react'
import { Button, Login, useModal, UserBlock } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import ProviderErrorModal from 'components/Menu/ProviderErrorModal'
import ChainErrorModal from 'components/Menu/ChainErrorModal'
import Container from './Container'

export interface Props {
  account?: string
  login: Login
  logout: () => void
  error?: any
}

const Navbar = styled.nav`
  padding: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1536px;
  margin: auto;
  background-color: white;
  border-radius: 50px;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`

const NavLeft = styled.div`
  flex: 150px;
  @media (max-width: 680px) {
    display: none;
  }
`

const NavCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
`

const NavRight = styled.div`
  flex: 150px;
  @media (max-width: 680px) {
    flex: 1
  }
`

const Brand = styled.a`
  display: flex;
  align-items: center;
  color: #0b698d;
  font-weight: bold;
  text-transform: uppercase;
  img {
    max-height: 46px;
    max-width: 100%;
    margin-right: 6px;
  }
  span {
    color: #5cbb4f;
    display: block;
  }
`

const NavLinks = styled.ul`
  display: inline-block;
  margin-left: 24px;
  padding-left: 12px;
  border-left: 1px solid #48586e;
  @media (max-width: 680px) {
    padding: 12px 0;
    margin: auto;
    border: none;
  }
  li {
    display: inline-block;
    list-style: none;
    padding: 8px 12px;
  }
  a {
    color: #cdd1d7;
    font-size: 16px;
    font-weight: bold;
  }
  a:hover,
  a:focus,
  a.active {
    color: rgb(59, 130, 246);
  }
`

const Nav: React.FC<Props> = ({ account, login, logout, error }) => {
  const [isInvalidBrowser, setInvalidBrowser] = useState(false)
  const [unsupportedChain, setUnsupportedChain] = useState(false)

  const [showProviderErrorModal] = useModal(<ProviderErrorModal />)

  const [showUnsupportedChainModal] = useModal(<ChainErrorModal />)

  useEffect(() => {
    if (!account && error) {
      if (error.name === 't' && !isInvalidBrowser) {
        showProviderErrorModal()
        setInvalidBrowser(true)
      }
      if (error.name === 'ChainUnsupportedError' && !unsupportedChain) {
        showUnsupportedChainModal()
        setUnsupportedChain(true)
      }
    } else {
      setInvalidBrowser(false)
      setUnsupportedChain(false)
    }
  }, [account, error, isInvalidBrowser, unsupportedChain, showProviderErrorModal, showUnsupportedChainModal])

  return (
    <Navbar>
      <Wrapper>
        <NavLeft />
        <NavCenter>
          <Brand href="/">
            <img src="/Logo.png" alt="Cexdex" />
          </Brand>
        </NavCenter>
        <NavRight>
          <UserBlock account={account} login={login} logout={logout} chainName="KAVA" chainURL="explorer.kava.io" />
        </NavRight>
      </Wrapper>
    </Navbar>
  )
}

export default Nav
