import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from 'contexts/ThemeContext'

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)

  // const primaryColor = '#31D0AA'
  const primaryColor = '#7453fc'
  const dark = '#282b2f'
  const light = '#cdd1d7'
  const darkPrimary = '#5c48a7'
  const textColor = '#212121'

  if (isDark) {
    theme.modal.background = dark
    theme.colors.text = light
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.colors.overlay = primaryColor
    theme.colors.primary = primaryColor
    theme.colors.primaryBright = primaryColor
    theme.colors.background = dark
    theme.colors.borderColor = `rgba(0,0,0,0.5)`
    theme.colors.input = `rgba(0,0,0,0.5)`
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.card.background = `rgba(0,0,0,0.2)`
    theme.shadows.focus = primaryColor
    theme.button.subtle.background = primaryColor
    theme.button.primary.background = primaryColor
    theme.button.primary.color = `#fff`
    theme.button.primary.backgroundActive = 'transparant'
    theme.button.primary.backgroundHover = `transparant`
    theme.button.secondary.border = `2px solid ${primaryColor}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.secondary.color = primaryColor
    theme.button.tertiary.color = primaryColor
    theme.button.text.color = primaryColor
  } else {
    theme.colors.background = '#ffffff'
    theme.colors.text = textColor
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.button.primary.background = primaryColor
    theme.button.primary.backgroundActive = primaryColor
    theme.button.primary.backgroundHover = '#ffffff'
    theme.button.primary.boxShadow = 'none'
    theme.button.primary.boxShadowActive = 'none'
    theme.button.primary.border = `1px solid ${primaryColor}`
    theme.button.primary.boxShadow = 'none'
    theme.button.primary.boxShadowActive = 'none'
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.colors.primary = darkPrimary
    theme.button.text.color = darkPrimary
    theme.button.secondary.color = darkPrimary
    theme.button.secondary.border = `2px solid ${darkPrimary}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.tertiary.background = primaryColor
    theme.button.tertiary.color = '#ffffff'
    theme.button.tertiary.backgroundHover = darkPrimary
  }

  return { isDark, toggleTheme, theme }
}

export default useTheme
